import { css } from '@emotion/react';
import React, { memo } from 'react';
import { useMount, useUnmount } from 'react-use';
import useMedia from '~/hooks/useMedia';
import { getQuoteTextColor } from '~/modules/AppLayout/colorUtils';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { useQuoteUpdateAnimationCSS } from '~/modules/SDK/socket/useQuoteUpdateAnimationCSS';
import { ToolTipSmallChartAi } from '~/modules/investment-consultant/symbolTrendChart/SymbolTrendChart/ToolTipSmallChart';
import { fontWeight600 } from '~/css/font';
import { Tooltip } from '@mantine/core';
import { useThemeOfParent } from '~/components/theme/useThemeOfParent';
import { useSnapshot } from 'valtio';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
export const SignalrOptionRefQuote = memo(function SignalrOptionRefQuote(props) {
    const { isPc } = useMedia();
    const symbolInfo = useSnapshot(signalrStore2.values.quote)[props.symbol];
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const quoteAnimation = useQuoteUpdateAnimationCSS(symbolInfo?.close);
    const parentTheme = useThemeOfParent();
    const isDark = parentTheme.isDark;
    useMount(() => {
        signalrStore2.addQuote(props.symbol);
    });
    useUnmount(() => {
        signalrStore2.removeQuote(props.symbol);
    });
    return (<Tooltip position='bottom' color='#55555a' label={<ToolTipSmallChartAi symbol={props.symbol} isDark={isDark}/>}>
      <div css={css `
          ${optionRefQuoteBoxCss}
          padding: ${isPc ? '8px 16px' : '8px'};
          border: ${getQuoteTextColor(quoteChanges.closeChangePercent, isDark)} 1px solid;
          #__next & {
            color: ${getQuoteTextColor(quoteChanges.closeChangePercent, isDark)};
          }
          ${quoteAnimation}
        `}>
        <p>
          <SymbolName symbol={props.symbol}/>
        </p>
        <p css={css `
            padding: 0px 5px;
          `}>
          {symbolInfo?.close}
        </p>
        <p css={css `
            color: ${getQuoteTextColor(quoteChanges.closeChange, isDark)};
          `}>
          ({(quoteChanges.closeChange > 0 ? '+' : '') + quoteChanges.closeChange})
        </p>
      </div>
    </Tooltip>);
});
const optionRefQuoteBoxCss = css `
  ${flex.h.allCenter};
  min-width: 256px;
  border-radius: 8px;
  p {
    margin: 0;
    ${fontWeight600};
    font-size: 1rem;
  }
`;
